import React from 'react'
import Heading from './shared/Heading'

export default (type, element, content, children, key) => (
  <Heading
    component='h2'
    fontSize={18 / 16}
    lineHeight={20 / 16}
    wordBreak='break-word'
    key={key}
  >
    {children}
  </Heading>
)
