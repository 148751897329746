import React from 'react'
import _ from 'lodash'
import Box from '../../../Box'

export default ({
  pageProps: {
    data: {
      prismic: {
        post: {
          _meta: {
            tags,
          },
        },
      },
    },
  },
}) => (
  <Box
    display='flex'
    justifyContent='center'
    paddingY={4}
  >
    {_.map(tags, (tag) => (
      <Box
        key={tag}
        fontFamily='Cabin'
        fontSize={18 / 16}
        paddingX={0.5}
      >
        {tag}
      </Box>
    ))}
  </Box>
)
