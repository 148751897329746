import React from 'react'
import Box from '../Box'
import Items from './Items'
import scale from '../Landing/Splash/lib/scale'

export default () => (
  <Box
  >
    <Box
      fontSize={scale(24, 46)}
      paddingTop={6}
      paddingBottom={2}
      fontWeight={500}
      color='grey4'
      fontFamily='Noto Nastaliq Urdu'
      wordBreak='break-word'
    >
      Naujausi įrašai
    </Box>
    <Items />
  </Box>
)
