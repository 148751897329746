import React from 'react'
import Box from '../../../../Box'

export default (type, element, content, children, key) => (
  <Box
    key={key}
    fontStyle='italic'
    display='inline'
  >
    {children}
  </Box>
)
