import React from 'react'
import Box from '../../Box'
import Input from '../../Input'
import SkewedButton from '../../SkewedButton'
import { useSubscribeForm } from '../../../hooks/useSubscribeForm'

export default () => {
  const { formikProps, isSubscribed } = useSubscribeForm()

  if (isSubscribed) {
    return (
      <Box
        fontSize={20 / 16}
        color='grey4'
        paddingTop={2}
      >
        Lauk mūsų laiškelio :)
      </Box>
    )
  }

  return (
    <Box
      component='form'
      onSubmit={formikProps.handleSubmit}
      display='flex'
      flexDirection={{ xs: 'column', lg: 'row' }}
      alignItems='center'
      paddingTop={2}
      name='subscribe'
      data-netlify='true'
    >
      <Input
        name='email'
        type='email'
        onChange={formikProps.handleChange}
        value={formikProps.values.email}
        placeholder='Tavo el. paštas'
      />
      <Box
        display='flex'
        paddingTop={{ xs: 1, lg: 0 }}
      >
        <SkewedButton
          onClick={formikProps.handleSubmit}
          backgroundColor='yellow5'
        >
          Prenumeruoti
        </SkewedButton>
      </Box>
    </Box>
  )
}
