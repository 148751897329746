import React from 'react'
import Heading from './shared/Heading'

export default (type, element, content, children, key) => (
  <Heading
    component='h2'
    fontSize={{ xs: 25 / 16, md: 32 / 16}}
    lineHeight={{ xs: 27 / 16, md: 35 / 16}}
    wordBreak='break-word'
    key={key}
  >
    {children}
  </Heading>
)
