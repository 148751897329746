import React from 'react'
import ReactPlayer from 'react-player'
import Box from '../../../../Box'

export default (type, element, content, children, key, { headingImageProps = {} } = {}) => {
  const {
    oembed: {
      embed_url,
    },
  } = element

  return (
    <Box
      key={key}
      display='flex'
      paddingY={1}
      {...headingImageProps}
    >
      <ReactPlayer
        url={embed_url}
        width='100%'
        height='500px'
      />
    </Box>
  )
}
