import { Elements } from 'prismic-reactjs';
import paragraph from './paragraph'
import image from './image'
import heading1 from './heading1'
import heading2 from './heading2'
import heading3 from './heading3'
import heading4 from './heading4'
import heading5 from './heading5'
import heading6 from './heading6'
import embed from './embed'
import preformatted from './preformatted'
import list from './list'
import listItem from './listItem'
import oList from './oList'
import oListItem from './oListItem'
import em from './em'
import strong from './strong'
import hyperlink from './hyperlink'

export default {
  [Elements.paragraph]: paragraph,
  [Elements.image]: image,
  [Elements.heading1]: heading1,
  [Elements.heading2]: heading2,
  [Elements.heading3]: heading3,
  [Elements.heading4]: heading4,
  [Elements.heading5]: heading5,
  [Elements.heading6]: heading6,
  [Elements.embed]: embed,
  [Elements.preformatted]: preformatted,
  [Elements.list]: list,
  [Elements.listItem]: listItem,
  [Elements.list]: list,
  [Elements.listItem]: listItem,
  [Elements.oList]: oList,
  [Elements.oListItem]: oListItem,
  [Elements.em]: em,
  [Elements.strong]: strong,
  [Elements.hyperlink]: hyperlink,
}
