import React from 'react'
import { RichText } from 'prismic-reactjs'
import htmlSerializer from './lib/htmlSerializer'

export default ({
  content,
  headingImageProps,
  ...rest
}) => (
  <RichText
    render={content}
    htmlSerializer={htmlSerializer({ headingImageProps })}
    {...rest}
  />
)
