import React from 'react'
import Heading from './shared/Heading'
import scale from '../../../../Landing/Splash/lib/scale'

export default (type, element, content, children, key, { headingImageProps = {} } = {}) => (
  <Heading
    component='h2'
    fontWeight={450}
    fontSize={scale(45, 110)}
    lineHeight={scale(50, 115)}
    wordBreak='break-word'
    key={key}
    {...headingImageProps}
  >
    {children}
  </Heading>
)
