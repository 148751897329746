import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../Layout'
import Box from '../Box'
import Subscribe from '../Subscribe'
import NewPosts from '../NewPosts'
import Support from '../Support'
import Footer from '../Footer'
import Topbar from '../Topbar'
import Content from './Content'

export const query = graphql`
  query PostQuery($uid: String!) {
    prismic {
      post(uid: $uid, lang: "lt") {
        title
        content
        date
        og_image
        og_title
        og_description
        reading_time

        author {
          ... on PRISMIC_Person {
            name
            avatar
          }
        }

        _meta {
          uid
          firstPublicationDate
          tags
        }
      }
    }
  }
`

export default (pageProps) => {
  const {
    data,
  } = pageProps

  if (!data) return null

  return (
    <Layout>
      <Box
        backgroundColor='yellow2'
        padding={2}
        paddingBottom={10}
        flexGrow={1}
      >
        <Topbar />
        <Content pageProps={pageProps} />
        <Box
          paddingTop={5}
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          <Box paddingBottom={2}>
            <Support />
          </Box>
          <NewPosts />
          <Subscribe />
        </Box>
      </Box>
      <Footer />
    </Layout>
  )
}
