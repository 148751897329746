import React from 'react'
import _ from 'lodash'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import Box from '../../Box'
import PostItem from '../../PostItem'

const query = graphql`
  {
    prismic {
      allPosts(first: 3, sortBy: date_DESC) {
        edges {
          node {
            title
            content
            reading_time
            is_video
            image
            imageSharp {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date

            author {
              ... on PRISMIC_Person {
                name
                avatar
              }
            }

            _meta {
              uid
              firstPublicationDate
            }
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={query}
    render={withPreview(({
      prismic: {
        allPosts: {
          edges,
        },
      },
    }) => (
      <Box
        display='grid'
        gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr', xl: '1fr 1fr 1fr' }}
        gridGap={{ xs: '16px', xl: '64px' }}
      >
        {_.map(edges, ({ node }) => (
          <PostItem
            key={node._meta.uid}
            post={node}
          />
        ))}
      </Box>
    ), query)}
  />
)
