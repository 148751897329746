import { useState } from 'react'
import { useFormik } from 'formik'
import onSubmit from './lib/onSubmit'
import validationSchema from './lib/validationSchema'

export const useSubscribeForm = () => {
  const [isSubscribed, setIsSubscribed] = useState(false)

  const formikProps = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: onSubmit({ isSubscribed, setIsSubscribed }),
  })

  return {
    formikProps,
    isSubscribed,
  }
}
