import React from 'react'
import Box from '../Box'

export default ({
  backgroundColor = 'grey2',
  ...rest
}) => (
  <Box
    display='flex'
    flexDirection='column'
    paddingRight={0.5}
    cursor='pointer'
  >
    <Box
      backgroundColor={backgroundColor}
      height={3}
      marginBottom={-2.5}
      marginLeft={0.5}
      marginRight={-0.5}
      borderRadius={8 / 16}
    />
    <Box
      fontFamily='Cabin'
      hoverBackgroundColor='hsla(209, 19%, 26%, 0.1)'
      fontSize={20 / 16}
      border='1px solid grey4'
      borderRadius={8 / 16}
      paddingX={1}
      paddingY={0.5}
      textAlign='center'
      {...rest}
    />
  </Box>
)
