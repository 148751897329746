import React from 'react'
import Box from '../../../Box'
import RichText from '../../../RichText'

export default ({
  pageProps: {
    data: {
      prismic: {
        post: {
          content,
        },
      },
    },
  },
}) => (
  <Box
    paddingY={3}
  >
    <RichText
      content={content}
    />
  </Box>
)
