import React from 'react'
import _ from 'lodash'
import { Helmet } from 'react-helmet';
import moment from 'moment'
import 'moment/locale/lt'
import { RichText } from 'prismic-reactjs'
import Box from '../../Box'
import Content from './Content'
import Tags from './Tags'

moment.locale('lt')

export default ({
  pageProps: {
    data: {
      prismic: {
        post,
      },
    },
  },
  pageProps,
}) => {
  if (!post) return null

  const {
    title,
    date,
    content,
    og_image: ogImage,
    og_title: ogTitle,
    og_description: ogDescription,
    reading_time,

    author,

    _meta: {
      firstPublicationDate,
    },
  } = post

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
    >
      <Helmet>
        <title>{ogTitle || title}</title>
        <meta property="og:title" content={ogTitle || title} />
        <meta name="description" content={ogDescription || _.truncate(RichText.asText(content), 140)} />
        <meta property="og:description" content={ogDescription || _.truncate(RichText.asText(content), 140)} />

        {ogImage && <meta property="og:image" content={ogImage.url} />}
        {ogImage && <meta property="og:image:url" content={ogImage.url} />}
      </Helmet>
      <Box
        maxWidth={40}
      >
        <Tags pageProps={pageProps} />
        <Box
          fontSize={46 / 16}
          lineHeight={60 / 16}
          fontWeight={700}
          color='grey4'
          textAlign='center'
          component='h1'
          fontFamily='Noto Nastaliq Urdu'
        >
          {title}
        </Box>
        <Box
          display='flex'
          justifyContent='center'
        >
          <Box
            marginY={2}
            height={4}
            width={1 / 16}
            backgroundColor='grey4'
          />
        </Box>
        <Box
          textAlign='center'
        >
          <Box>
            {author?.name}
          </Box>
          <Box
            fontSize={14 / 16}
            paddingTop={0.5}
          >
            {moment(date || firstPublicationDate).format('YYYY-MM-DD')}
          </Box>
          {reading_time && <Box
            fontSize={14 / 16}
            paddingTop={0.5}
            color='hsla(211, 19%, 56%, 1)'
          >
            {reading_time}
          </Box>}
        </Box>

        <Content
          pageProps={pageProps}
        />
      </Box>
    </Box>
  )
}
