import React from 'react'
import Box from '../../../../Box'

export default (type, element, content, children, key, { headingImageProps = {} } = {}) => {
  const {
    url,
    alt,
  } = element

  const isPushed = headingImageProps?.marginLeft

  return (
    <Box
      paddingY={2}
      key={key}
      display='flex'
      flexDirection={{ xs: 'column-reverse', lg: isPushed ? 'column-reverse' : 'row' }}
      {...headingImageProps}
    >
      <Box
        width={{ xs: 'auto', lg: isPushed ? 'auto' : 10 }}
        flexShrink={{ xs: 1, lg: isPushed ? 1 : 0 }}
        marginLeft={{ xs: 0, lg: isPushed ? 0 : -10 }}
        paddingRight={{ xs: 0, lg: isPushed ? 0 : 1 }}
        paddingTop={{ xs: 0, lg: isPushed ? 0 : 4 }}
        fontFamily='Cabin'
        color='grey4'
        wordBreak='break-word'
      >
        {alt}
      </Box>
      <Box>
        <Box
          component='img'
          src={url}
          width='100%'
        />
      </Box>
    </Box>
  )
}
