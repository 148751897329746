import React from 'react'
import Box from '../../../../../Box'
import isLocal from './isLocal'
import component from './component'
import to from './to'

export default (type, element, content, children, key) => {
  const {
    data: {
      url,
      target,
    },
  } = element

  return (
    <Box
      key={key}
      component={component({ element })}
      textDecoration='underline'
      hoverColor='grey9'
      display='inline'
      {...(target ? { target, rel: 'noopener' } : {})}
      {...(isLocal({ element }) ? { to: to({ element }) } : { href: url })}
    >
      {children}
    </Box>
  )
}
