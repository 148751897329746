import React from 'react'
import Heading from './shared/Heading'

export default (type, element, content, children, key) => {
  const {
    text,
  } = element

  return (
    <Heading
      marginLeft={{ xs: 0, lg: -10 }}
      key={key}
      fontSize={{ xs: 28 / 16, lg: 40 / 16 }}
      lineHeight={{ xs: 30 / 16, lg: 50 / 16 }}
      fontWeight={450}
      wordBreak='break-word'
    >
      {text}
    </Heading>
  )
}
