import React, { Children } from 'react'
import _ from 'lodash'
import Box from '../../../../Box'

export default (type, element, content, children, key) => {
  if (_.isEmpty(Children.toArray(children))) {
    return null
  }

  return (
    <Box
      fontSize={22 / 16}
      lineHeight={35 / 16}
      fontFamily='Cabin'
      paddingY={1}
      color='grey4'
      key={key}
      wordBreak='break-word'
    >
      {children}
    </Box>
  )
}
