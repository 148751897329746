import React from 'react'
import { Link } from 'gatsby'
import SkewedButton from '../SkewedButton'
import Box from '../Box'
import scale from '../Landing/Splash/lib/scale'

export default () => (
  <Box
    display='flex'
    flexDirection='column'
    alignItems='center'
  >
    <Box
      fontSize={scale(24, 46)}
      fontWeight={500}
      color='grey4'
      fontFamily='Noto Nastaliq Urdu'
      wordBreak='break-word'
      textAlign='center'
    >
      Prisidėk prie mūsų misijos
    </Box>

    {false && <Box
      fontSize={scale(16, 22)}
      color='grey4'
      fontFamily='Noto Nastaliq Urdu'
      wordBreak='break-word'
      textAlign='center'
    >
      Švietimo nuotykiai gyvuoja sutelktinio finansavimo - jūsų dėka.
    </Box>}

    <Box
      paddingTop={2}
      display='grid'
      gridTemplateColumns={{ xs: '1fr', lg: '1fr 1fr' }}
      gridGap='32px'
    >
      <SkewedButton
        component='a'
        href='https://contribee.com/svietimo-nuotykiai'
        backgroundColor='yellow5'
        fontWeight={500}
        target='_blank'
        rel="noopener noreferrer"
      >
        Contribee
      </SkewedButton>

      {false && <SkewedButton
        component='a'
        href='https://www.patreon.com/svietimonuotykiai'
        backgroundColor='yellow8'
        fontWeight={500}
        target='_blank'
        rel="noopener noreferrer"
      >
        Patreon
      </SkewedButton>}

      {false && <SkewedButton
        component='a'
        href='https://gogetfunding.com/united-nation-of-education/'
        backgroundColor='yellow5'
        fontWeight={500}
        target='_blank'
        rel="noopener noreferrer"
      >
        GoGetFunding
      </SkewedButton>}

      <SkewedButton component={Link} to='/parama' backgroundColor='yellow7'>
        Kitaip
      </SkewedButton>
    </Box>
  </Box>
)
