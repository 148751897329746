import serializers from './serializers'

export default (opts) => (...args) => {
  const [type] = args

  const serializer = serializers[type]
  if (!serializer) return null

  return serializer(...args, opts)
}
