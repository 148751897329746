// FROM https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/
const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default ({
  isSubscribed,
  setIsSubscribed,
}) => async (values, { resetForm }) => {
  if (isSubscribed) return

  await fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': "application/x-www-form-urlencoded" },
    body: encode({ 'form-name': 'subscribe', ...values })
  })

  setIsSubscribed(true)
  resetForm()
}
