import React from 'react'
import Box from '../../../../../Box'

export default (props) => (
  <Box
    fontSize={30 / 16}
    paddingY={1}
    color='grey4'
    fontWeight={700}
    fontFamily='Noto Nastaliq Urdu'
    {...props}
  />
)
