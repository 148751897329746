import React from 'react'
import Box from '../Box'
import Form from './Form'
import scale from '../Landing/Splash/lib/scale'

export default () => (
  <Box
    padding={{ xs: 2, md: 4 }}
    margin={1}
    display='flex'
    flexDirection='column'
    alignItems='center'
  >
    <Box
      fontSize={scale(24, 46)}
      lineHeight={scale(27, 50)}
      fontWeight={500}
      color='grey4'
      textAlign='center'
      fontFamily='Noto Nastaliq Urdu'
      paddingBottom={0.5}
    >
      Prenumeruok Naujienlaiškį*
    </Box>
    <Box
      fontSize={20 / 16}
      color='grey4'
      textAlign='center'
      fontFamily='Noto Nastaliq Urdu'
    >
      *ir sulauk Švietimo nuotykių savo pašte
    </Box>
    <Form />
  </Box>
)
