import React from 'react'
import Heading from './shared/Heading'

export default (type, element, content, children, key) => (
  <Heading
    component='h2'
    fontSize={{ xs: 30 / 16, md: 40 / 16}}
    lineHeight={{ xs: 35 / 16, md: 45 / 16}}
    wordBreak='break-word'
    key={key}
  >
    {children}
  </Heading>
)
