import React from 'react'
import Box from '../Box'

export default ({
  backgroundColor = 'grey2',
  ...rest
}) => (
  <Box
    display='flex'
    flexDirection='column'
    flexGrow={1}
    paddingRight={0.5}
  >
    <Box
      backgroundColor={backgroundColor}
      height={3}
      marginBottom={-2.5}
      marginLeft={0.5}
      marginRight={-0.5}
      borderRadius={8 / 16}
    />
    <Box
      component='input'
      fontFamily='Cabin'
      fontSize={20 / 16}
      border='1px solid grey4'
      borderRadius={8 / 16}
      paddingX={1}
      paddingY={0.5}
      {...rest}
    />
  </Box>
)
