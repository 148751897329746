import React from 'react'
import Box from '../../../../Box'

export default (type, element, content, children, key) => (
  <Box
    key={key}
    component='ol'
    listStylePosition='inside'
  >
    {children}
  </Box>
)
